<template>
    <div class="daqi-copyright">
      <img src="@/assets/images/daqi-copyright.png">
      <span>大旗软件提供技术支持</span>
    </div>
</template>

<script>
	export default {
		name:"daqi-copyright"
	}
</script>
<style lang="scss" type="text/scss" scoped>
    // @import "~@/assets/style/public.scss";
    .daqi-copyright{
      padding-top: 25px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img{width: 100px;height: 42px;margin-bottom: 10px}
      span{font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #666666}
    }
    // .daqi-copyright{
    //     padding:10px 0 20px;
    //     .logo{
    //         height:71px;
    //         width:180px;
    //         background:url("~@/assets/images/daqi-copyright.png") no-repeat;
    //         background-size:100%;
    //         margin:0px auto;
    //     }
    // }
</style>
