<template>
    <div class="scroll-container" id="scrollTechBox" @scroll.stop="showScroll()">
        <div class="main-content">
            <empty-box v-if="techList&&techList.length==0"></empty-box>
            <div class="tech-group-item"
                 v-for="(item,index) in techList"
                 v-else
                 :key="index">
                <h3 class="type-name">
                    {{index}}
                    <span class="sum-num">({{item.length}})</span>
                </h3>
                <ul class="tech-list">
                    <li v-for="tech in item"
                        class="tech-item"
                        :class="itemClass(tech.work_state)"
                        :key="tech.staff_no"
                        @click="showTechDetail(tech.staff_work_no)">
                        <p class="staff-no">
                            <span>{{tech.staff_work_no}}</span>
                            <i v-if="tech.is_reserve==1" class="reserve-icon">约</i>
                        </p>
                        <div class="tech-detail">
                            <p class="work-detail" v-if="tech.work_state == '2'">
                                <span>{{tech.room_no}}</span>
                                <span class="clock-type">{{tech.work_type|workType}}</span>
                                <span :class="tech.last_time<0? 'red-font' :'green-font'">
                                    余{{tech.last_time}}
                                </span>
                            </p>
                            <p class="work-state" v-if="tech.work_state != '2'">{{tech.work_state|workState}}</p>
                        </div>
                    </li>
                    <!--space-between 最后一行未左端对齐的处理方案-->
                    <!-- <li class="tech-item" v-for="index in (item.length%rowNum)" :key="index"></li> -->
                    <li class="tech-item" v-for="index in 4" :key="index"></li>
                </ul>
            </div>
        </div>
        <daqi-copyright></daqi-copyright>
    </div>
</template>

<script>
	import table from '@/api/table'
	import DaqiCopyright from "../../../components/daqi-copyright";

	export default {
		name:"technician-type",
		props:["techList"],
		data(){
			return {
				staffNo:null,
				rowNum:4,
				scrollTop:0,
			}
		},
		components:{
			DaqiCopyright,
			'empty-box':() => import('@/components/empty-box.vue'),
		},
		computed:{
			itemClass(){
				return function(state){
					switch(state){
						case 1:
							return 'idle'
							break
						case 2:
							return 'upper-clock'
							break
						case 3:
							return 'lock-card'
							break
						case 4:
							return 'off-work'
							break
						case 5:
							return 'vacation'
						case 6:
							return 'beautiful'
					}
				}
			},
		},
		filters:{
			workType(type){
				switch(type){
					case 1:
						return '轮'
						break
					case 2:
						return '点'
						break
					case 3:
						return 'Call'
						break
					case 4:
						return '加'
						break
					case 5:
						return '跳'
						break
				}
			},
			workState(state){
				switch(state){
					case 1:
						return '空闲'
						break
					case 2:
						return '上钟'
						break
					case 3:
						return '圈牌'
						break
					case 4:
						return '下班'
						break
					case 5:
						return '休假'
					case 6:
						return '选美'
						break
				}
			},
		},
		methods:{
			showTechDetail(staffNo){
				let params = {
					staffNo
				}
				table.getTechnicianDetail(params).then(res =>{
					this.$store.commit('updateTechDetail',res.data)
					this.$store.commit('updateShowTechDetail',true)
				}).catch(err =>{
					console.log("err",err.msg)
				})

			},
			showScroll(){
				this.scrollTop = document.getElementById('scrollTechBox').scrollTop
			},
		},
		watch:{
			scrollTop:{
				handler(val){
					if (val === 0) {
						this.$store.commit("updateAllowPull",true)
					}else{
						this.$store.commit("updateAllowPull",false)
					}
				}
			},
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .scroll-container{
        overflow-y:auto;
        flex:1;
        padding:18px 0 0;
    }
    .main-content{
        min-height:calc(100% - 101px);
    }
    .tech-group-item{
        background:#FFFFFF;
        padding:0 30px;
    }

    .type-name{
        font-size:28px;
        font-weight:bold;
        line-height:80px;
        border-bottom:1px solid $border-color;
    }

    .sum-num{
        color:$high-light-color;
    }

    .tech-list{
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        padding:23px 0 5px 0;
    }

    .tech-item{
        width:162px;
        margin:0 0 17px;
    }

    .staff-no{
        display:flex;
        justify-content:space-between;
        align-items:center;
        color:#FFFFFF;
        font-size:28px;
        line-height:50px;
        height:50px;
        padding:0 10px;
        background:#15BB44;
        border-radius:4px 4px 0 0;
    }

    .idle .staff-no{
        background:#93A3B5;
    }

    .lock-card .staff-no{
        background:#5B3CFC;
    }

    .off-work .staff-no{
        background:#98c9fd;
    }

    .vacation .staff-no{
        background:#F77E4E;
    }

    .beautiful .staff-no{
        background:#e13744;
    }

    .tech-detail{
        padding:0 8px 10px 8px;
        border-left:2px solid $border-color;
        border-right:2px solid $border-color;
        border-bottom:2px solid $border-color;
        border-radius:0 0 4px 4px;
    }

    .work-state,
    .work-detail{
        color:#666666;
        font-size:22px;
        line-height:45px;
        height:45px;
        display:flex;
        span{
            flex:1;
            text-align:center;
            white-space:nowrap;
        }
        .clock-type{
            padding:0 2px;
        }
    }

    .red-font{
        color:#FF6666;
    }

    .green-font{
        color:#15BB44;
    }

    .tech-icon{
        height:45px;
    }

    .reserve-icon{
        display:inline-block;
        width:30px;
        height:30px;
        background:#fff;
        border-radius:5px;
        color:#222;
        font-size:24px;
        text-align:center;
        line-height:30px;
    }

</style>
